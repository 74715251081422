<script lang="ts">
    import { page } from '$app/stores';
  	import { fade } from "svelte/transition";
	  import SideBarLink from "./SideBarLink.svelte";
	  import HeaderLink from "./HeaderLink.svelte";
	  import SideBarSubLink from "./SideBarSubLink.svelte";
	  import UserAccountAvatar from '$lib/components/UserAccountAvatar.svelte';
	  import { Capacitor } from '@capacitor/core';
	  import { activity_sync_store } from '$lib/stores/activity_sync_store';
  	import { user_session } from '$lib/stores/user_session_store';
	  import AlertDropdownComponent from '$lib/components/alert/AlertDropdownComponent.svelte';
	  import LoginTakeover from '$lib/components/homepage/LoginTakeover.svelte';
	  import { afterNavigate, goto } from '$app/navigation';
  	import { show_login } from '$lib/stores/login_store';
  	import env_client from '$lib/env/env_clientside';
  	import features_framework_clientside from '$lib/features/features_framework_clientside';
	  import { header_menu_enabled } from '$lib/stores/header_menu_enabled_store';
	  import { Dropdown } from 'flowbite-svelte';

    const HEADER_ALWAYS_VISIBLE = false;
    const is_ios = false; // Capacitor.getPlatform() === 'ios';

    afterNavigate(() => {
        menu_open = false;
        profile_menu_open = false;
        // show_login.set(false);
    });

    let user_session_store = user_session();
    let {user_settings, user_account_uuid} = $user_session_store;

    let menu_open: boolean = false;
    let profile_menu_open: boolean = false;
    let user_uuid: string | undefined = user_account_uuid;

    function toggle_popup_menu(event:any) {
        menu_open = !menu_open;
    }

    function toggle_profile_menu(event:any) {
        profile_menu_open = !profile_menu_open;
    }

    async function logout() {
        window.location.href = '/auth/logout';
    }

    show_login.subscribe((visible) => {
        if (visible) {
            menu_open = false;
            profile_menu_open = false;
        }
    });

    function show_auth_modal() {
        show_login.set(true);
        menu_open = false;
        profile_menu_open = false;
    }

    // NOTE: reactive ($:) ensures that when doing client-side navigation the variable is always updated with current state (and not just when page is rendered)
    $: user_settings = user_settings?.enabled ? user_settings : undefined; 
    $: is_onboarding = $page.url.pathname.startsWith("/onboarding/");
</script>

{#if env_client.is_dev()}
  <!--
    Emulator form factors:
    
    iPhone 14 Pro Max (vertical) - default
    iPhone 14 Pro Max (horizontal) - md
    iPad Pro (vertical) - lg
    iPad Pro (horizontal) - lg
    iPad Air/Mini (vertical) - md
    iPad Air/Mini (horizontal) - lg
    Galaxy Z Fold 5 (vertical - folded) - sm
    Galaxy Z Fold 5 (horizontal - folded) - md
  -->
  <div class="print:hidden absolute top-0 left-0 px-2 py-1 text-[0.6rem] rounded-lg bg-white z-[999]">
    <div class="block sm:hidden">
      Default
    </div>
    <div class="hidden sm:block md:hidden">
      SM
    </div>
    <div class="hidden md:block lg:hidden">
      MD
    </div>
    <div class="hidden lg:block xl:hidden">
      LG
    </div>
    <div class="hidden xl:block">
      XL
    </div>
  </div>
{/if}
<header class="print:bg-white flex-0 z-[100] {HEADER_ALWAYS_VISIBLE ? `fixed ${is_ios ? 'pt-12 md:pt-4 bg-backdrop-dark' : ''} top-0 right-0 w-full` : ''}">
  
  <!-- Print-only header -->
  <div class="hidden print:flex print:place-content-end print:p-4">
    <div class="text-xs align-text-bottom gap-x-2 flex place-items-center">
      Powered by
      <img class="h-5 w-auto" src="/logo/swimerize-logo-dark-bg.svg" alt="">
    </div>
  </div>

  <!-- Non-print screen header -->
  <div class="print:hidden bg-backdrop-dark py-1">
    <nav class="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 h-10 sm:h-14" aria-label="Global">
      <div class="flex flex-1 items-center">
        <div class="flex w-full items-center justify-between md:w-auto">
          {#if !is_onboarding && $header_menu_enabled}
            <a href="{user_settings ? '/feed' : '/'}">
              <span class="sr-only">Swimerize</span>
              <img class="h-6 w-auto sm:h-8" src="/logo/swimerize-logo-dark-bg.svg" alt="">
            </a>
          {:else}
            <div>
              <span class="sr-only">Swimerize</span>
              <img class="h-6 w-auto sm:h-8" src="/logo/swimerize-logo-dark-bg.svg" alt="">
            </div>
          {/if}
          {#if $activity_sync_store.active}
          <div class="bg-backdrop-light text-primary-text rounded-md px-2 py-1 text-xs flex gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" 
                 class="w-4 h-4 animate-rotate-360"
                 style="animation-iteration-count: infinite; animation-duration: 1500ms">
              <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
            </svg>            
            Apple Watch
          </div>
          {/if}
          {#if $header_menu_enabled}
            {#if !is_onboarding}
              <div class="-mr-2 flex items-center md:hidden">
                <div class="flex place-items-center">
                  <AlertDropdownComponent />
                </div>
                <button on:click={toggle_popup_menu} type="button" class="focus-ring-inset inline-flex items-center justify-center rounded-md bg-backdrop-dark p-2 text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white" aria-expanded="false">
                  <span class="sr-only">Open main menu</span>
                  <!-- Heroicon name: outline/bars-3 -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </button>
              </div>
            {/if}
          {/if}
        </div>
        <div class="hidden space-x-8 md:ml-10 md:flex items-center">
          {#if $header_menu_enabled}
            {#if user_settings}
              <HeaderLink url="/home" text="Dashboard" popup_menu={false}/>
              <HeaderLink url="/feed" text="Feed" popup_menu={false}/>
              <HeaderLink url="/home/{user_account_uuid}/groups" text="Groups" popup_menu={false}/>
              <HeaderLink url="/workouts" text="Workouts" popup_menu={false}/>
              <!-- <HeaderLink url="/activities" text="Swims" popup_menu={false}/> -->
              {#if features_framework_clientside.is_courses_enabled()}
                <HeaderLink url="/courses" text="Courses" popup_menu={false}/>
              {/if}
            {:else}
              <!-- Turned off during Beta -->
              <!-- <HeaderLink url="/activities" text="Swims" popup_menu={false}/>
              <HeaderLink url="/courses" text="Courses" popup_menu={false}/> -->
            {/if}
          {/if}
        </div>
      </div>
      <!-- Profile dropdown -->
      {#if $header_menu_enabled}
        {#if user_settings}

            <!-- <div class="hidden md:block ml-3" >
              <div> -->
                <button type="button" on:click={toggle_profile_menu} class="hidden md:flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                  <span class="sr-only">Open user menu</span>
                  <UserAccountAvatar {user_settings} class="size-9"/>
                </button>
              <!-- </div>
            </div>   -->

            <Dropdown placement={'bottom-start'} class="p-2"> 

                <!--
                  Dropdown menu, show/hide based on menu state.
                -->
                <li >
                  <!-- Active: "bg-gray-100", Not Active: "" -->
                  <a href="/home/{user_uuid}/profile" 
                    on:click={toggle_profile_menu} 
                    class="block py-2 px-4 text-sm text-gray-700" 
                    role="menuitem" 
                    tabindex="-1" 
                    id="user-menu-item-1"
                    data-sveltekit-reload>
                      Your Profile
                  </a>
                </li>

                <li>
                  <a href="/home/settings" 
                      on:click={toggle_profile_menu} 
                      class="block py-2 px-4 text-sm text-gray-700" 
                      role="menuitem" 
                      tabindex="-1" 
                      id="user-menu-item-1">
                    Account Settings
                  </a>
                </li>

                <li> 
                  <button on:click={logout} on:keypress={logout} 
                          class="block py-2 px-4 text-sm text-gray-700" 
                          role="menuitem" 
                          tabindex="-1" 
                          id="user-menu-item-2">
                    Sign out
                  </button>
                </li>
            </Dropdown>
        {:else}
          <div class="hidden sm:flex sm:flex-row sm:gap-5 sm:items-center">
            <div class="flex-0 mx-auto ">
              <!-- <a href="/signup/" class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-1 text-base font-medium text-white hover:bg-gray-700">
                Sign up!
              </a> -->
            </div>                
            <div class="mx-auto text-sm text-slate-300 hover:text-gray-300">
              <span on:click={show_auth_modal} 
                    on:keypress={show_auth_modal} 
                    class="text-base font-medium text-slate-300 hover:text-gray-300"
                    role="button"
                    tabindex="0">
                <span class="underline">Sign up</span> <span class="text-xs mx-2">or</span> Log in
              </span>
              <!-- <a href="/auth/signin" class="text-base underline font-medium text-slate-300 hover:text-gray-300">
                - Log in alternate
              </a> -->
            </div>
          </div>
        {/if}
      {/if}
      <!-- <div class="hidden md:flex md:items-center md:space-x-6">
        {#if session.is_logged_in}
          <a href="/auth/logout" class="text-base font-medium text-white hover:text-gray-300">Log out</a>
        {:else}
          <a href="/auth/login" class="text-base font-medium text-white hover:text-gray-300">Log in</a>
          <a href="/signup/" class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white hover:bg-gray-700">Sign up!</a>
        {/if}
      </div> -->
    </nav>
  </div>

    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. class:hidden={!sidebar_open} -->
    {#if menu_open}
      
      <div class="relative z-40 lg:hidden" role="dialog" aria-modal="true">
        <!--
          Off-canvas menu backdrop, show/hide based on off-canvas menu state.
        -->
        <div transition:fade
             class="fixed inset-0 bg-black bg-opacity-80"></div>
    
        <div class="fixed inset-0 z-40 flex">
          <!--
            Off-canvas menu, show/hide based on off-canvas menu state.
          -->
          <div transition:fade class:pt-8={Capacitor.getPlatform() === 'android'} class="relative flex w-full max-w-xs flex-1 flex-col bg-backdrop-dark text-slate-300 focus:outline-none safe-top">
            <!--
              Close button, show/hide based on off-canvas menu state.
            -->
            <div transition:fade class="absolute top-0 right-0 -mr-12 pt-2">
              <button on:click={toggle_popup_menu} type="button" class:mt-8={Capacitor.getPlatform() === 'android'} class="safe-top ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none">
                <span class="sr-only">Close sidebar</span>
                <!-- Heroicon name: outline/x-mark -->
                <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
    
            <div class="pt-1 pb-5">
              <div class="flex flex-shrink-0 items-center px-4 h-10">
                <img class="h-6 w-auto" src="/logo/swimerize-logo-dark-bg.svg" alt="">
              </div>
              <nav aria-label="Sidebar" class="mt-5">
                <div class="space-y-1 px-2">

                  {#if user_settings}
                    <!-- outline/home -->
                    <SideBarLink mobile={true} disable_selected_highlight={true} on:menu-item-click={toggle_popup_menu} url="/home" label="Dashboard" icon_svg_path="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />                    

                    <SideBarSubLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/home" label="Overall Stats" icon_svg_path="M15.5 2A1.5 1.5 0 0014 3.5v13a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0016.5 2h-1zM9.5 6A1.5 1.5 0 008 7.5v9A1.5 1.5 0 009.5 18h1a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0010.5 6h-1zM3.5 10A1.5 1.5 0 002 11.5v5A1.5 1.5 0 003.5 18h1A1.5 1.5 0 006 16.5v-5A1.5 1.5 0 004.5 10h-1z" />
                    <SideBarSubLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/home/{user_uuid}/swims" label="Your Swims" icon_svg_path="M2.5 3A1.5 1.5 0 001 4.5v4A1.5 1.5 0 002.5 10h6A1.5 1.5 0 0010 8.5v-4A1.5 1.5 0 008.5 3h-6zm11 2A1.5 1.5 0 0012 6.5v7a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-7A1.5 1.5 0 0017.5 5h-4zm-10 7A1.5 1.5 0 002 13.5v2A1.5 1.5 0 003.5 17h6a1.5 1.5 0 001.5-1.5v-2A1.5 1.5 0 009.5 12h-6z" />
                    <SideBarSubLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/home/{user_uuid}/friends" label="Friends" icon_svg_path="M11 5a3 3 0 11-6 0 3 3 0 016 0zM2.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 018 18a9.953 9.953 0 01-5.385-1.572zM16.25 5.75a.75.75 0 00-1.5 0v2h-2a.75.75 0 000 1.5h2v2a.75.75 0 001.5 0v-2h2a.75.75 0 000-1.5h-2v-2z" />
                    <SideBarSubLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/home/{user_uuid}/profile" full_page_reload={true} label="Profile" icon_svg_path="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    <!-- <SideBarSubLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/home/profile" label="Tags" icon_svg_path="M5.5 3A2.5 2.5 0 003 5.5v2.879a2.5 2.5 0 00.732 1.767l6.5 6.5a2.5 2.5 0 003.536 0l2.878-2.878a2.5 2.5 0 000-3.536l-6.5-6.5A2.5 2.5 0 008.38 3H5.5zM6 7a1 1 0 100-2 1 1 0 000 2z" /> -->

                    <!-- outline/chat-bubble-left-right -->
                    <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/feed" label="Feed" icon_svg_path="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />

                    <!-- outline/map -->
                    <!-- <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/activities" label="Swims" icon_svg_path="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" /> -->

                    <!-- outline/globe-alt -->
                    <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/home/{user_uuid}/groups" label="Groups & Clubs" icon_svg_path="M10 9a3 3 0 100-6 3 3 0 000 6zM6 8a2 2 0 11-4 0 2 2 0 014 0zM1.49 15.326a.78.78 0 01-.358-.442 3 3 0 014.308-3.516 6.484 6.484 0 00-1.905 3.959c-.023.222-.014.442.025.654a4.97 4.97 0 01-2.07-.655zM16.44 15.98a4.97 4.97 0 002.07-.654.78.78 0 00.357-.442 3 3 0 00-4.308-3.517 6.484 6.484 0 011.907 3.96 2.32 2.32 0 01-.026.654zM18 8a2 2 0 11-4 0 2 2 0 014 0zM5.304 16.19a.844.844 0 01-.277-.71 5 5 0 019.947 0 .843.843 0 01-.277.71A6.975 6.975 0 0110 18a6.974 6.974 0 01-4.696-1.81z" />
                    <!-- <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/courses" label="Outdoor Swims" icon_svg_path="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" /> -->

                    <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99" />
                    </svg> -->
                    <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/workouts" label="Workouts" icon_svg_path="M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99" />
                    

                  {:else}
                    <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/" label="Homepage" icon_svg_path="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    <!-- <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/activities" label="Swims" icon_svg_path="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                    <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/courses" label="Courses" icon_svg_path="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" /> -->

                  {/if}
                </div>
              </nav>
            </div>
            {#if user_settings}
              <div class="flex flex-shrink-0 border-t border-gray-200 px-4 pt-10 mt-5">
                  <div class="flex items-start">
                    <UserAccountAvatar {user_settings} class="inline-block size-10" />
                    <div class="ml-3">
                      <a href="/home/settings" class="group block flex-shrink-0" on:click={toggle_popup_menu} on:keypress={toggle_popup_menu}>
                        <p class="text-base font-medium text-slate-300">{user_settings.display_name}</p>
                        <p class="text-sm font-medium text-primary-text-diminished">Account Settings</p>
                      </a>
                      <p class="mt-4 text-sm font-medium text-primary-text-diminished">
                        <span on:click={logout} on:keypress={logout} role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</span>
                      </p>
                    </div>
                  </div>
              </div>
            {:else}
              <div class="flex flex-col gap-5 pt-10 my-5 border-t border-gray-200">
                <!-- <div class="flex-0 mx-auto ">
                  <a href="/signup/" class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white hover:bg-gray-700">
                    Sign up!
                  </a>
                </div>                 -->
                <div class="mx-auto text-sm text-slate-300 hover:text-gray-300 justify-end">
                  <span on:click={show_auth_modal} 
                        on:keypress={show_auth_modal} 
                        class="text-base font-medium text-slate-300 hover:text-gray-300"
                        role="button"
                        tabindex="0">
                    <span class="underline">Sign up</span> <span class="text-xs font-light mx-2">or</span> Log in
                  </span>
                </div>
              </div>
            {/if}
              
          </div>
    
          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </div>
    {/if}
</header>
{#if HEADER_ALWAYS_VISIBLE}
  <div class="mb-12 md:mb-16">

  </div>
{/if}
<LoginTakeover />
