<script lang="ts">

    import { Notification, NotificationState, notification_store } from "$lib/stores/notification_store";
	import { onDestroy } from "svelte";
	import { bounceOut } from "svelte/easing";
	import { fade, fly } from "svelte/transition";
	import { flip } from "svelte/animate";
       
    let notification_state: NotificationState;

    // action callback wrapper
    function handle_action(notification: Notification) {

        if (notification.action_callback !== undefined) {

            notification.action_callback();
        }

        notification_store.dismiss(notification.uuid);
    }

    function handle_dismiss(notification: Notification) {
     
        notification_store.dismiss(notification.uuid);
    }

    // handle new notifications 
    let unsubscribe_notification = notification_store.subscribe((state) => {
        notification_state = state;
        // if (state.notifications.length !== 0) {
        //     console.log("Detected upgrade notification.", state);
        // }
    });
    onDestroy(unsubscribe_notification);

</script>
  
<!-- Global notification live region, render this permanently at the end of the document -->
<div aria-live="assertive" class="print:hidden pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6 z-[999]">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <!--
        Notification panel, dynamically insert this into the live region when it needs to be displayed
  
        Entering: "transform ease-out duration-300 transition"
          From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          To: "translate-y-0 opacity-100 sm:translate-x-0"
        Leaving: "transition ease-in duration-100"
          From: "opacity-100"
          To: "opacity-0"
        -->
        {#if notification_state.notifications.length !== 0}

            {#each notification_state.notifications as notification (notification.uuid)}

                <div in:fly={{delay: 100, y:-300, duration:1000, opacity: 1, easing: bounceOut }} 
                     out:fade={{duration: 500}}
                     animate:flip="{{delay: 100, duration: 1000, easing: bounceOut}}"

                    class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">

                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="flex-shrink-0">
                                {@html notification.icon}
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-medium text-gray-900">{notification.header}</p>
                                <p class="mt-1 text-sm text-gray-500">{notification.text}</p>
                                <div class="mt-3 flex space-x-7">
                                    {#if notification.action_callback !== undefined}
                                        <button on:click={() => {handle_action(notification)}} 
                                                on:keypress={() => {handle_action(notification)}}
                                                type="button" 
                                                class="rounded-md bg-white text-sm font-medium text-primary-enabled focus:outline-none">
                                            {notification.action_label}
                                        </button>
                                    {/if}
                                    <button on:click={() => {handle_dismiss(notification)}} 
                                            on:keypress={() => {handle_dismiss(notification)}} 
                                            type="button" 
                                            class="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none">Dismiss</button>
                                </div>
                            </div>
                            <div class="ml-4 flex flex-shrink-0">
                                <button on:click={() => {handle_dismiss(notification)}} 
                                        on:keypress={() => {handle_dismiss(notification)}} 
                                        type="button" 
                                        class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none">
                                    <span class="sr-only">Close</span>
                                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                    
            {/each}

        {/if}
    </div>
</div>
